
.countdown {
 display: flex;
 align-items: center;
 justify-content: center;
 margin: auto;
}



.countdown {
 position: relative;
 width: 74px;
 height: 74px;
 border-radius: 50%;
 background-color: #F3F5F9;
 background-size: cover;
 background-position: center center;
 border: 3px solid grey;
 transform-origin: center bottom;
}

.countdown__icon {
 position: absolute;
 top: -6px;
 left: -6px;
 width: 80px;
 fill: transparent;
 stroke: #DF294E;
 stroke-width: 10px;
 stroke-linecap: round;
}

.countdown__icon__circle {
 stroke-dasharray: 375.787322998;
}

.countdown__number {
 color: #000;
}

.rebutton { display: flex; flex-direction: row; align-items: center;}
.refreshbutton { width: 30px; display:block; margin-left: 10px; transition: all linear 0.1s;}
.refreshbutton:active { transform: scale(0.8);}
.refreshbutton img { width: 100%;}

.disabledrefreshbutton { width: 30px; display:block; margin-left: 10px; transition: all linear 0.1s;}
.disabledrefreshbutton img { width: 100%;}

