/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";

.width-100-per {
  width: 100%;
}

.mat-form-field-appearance-fill{}

.mat-header-cell:last-child, .mat-cell:last-child {
  justify-content: flex-end;
}

.mat-cell {
  font-size: 10px !important;
}
mat-table mat-cell{
  font-size: 10px !important;
}

.form-element{
  width: 100%;
}

.content-wrapper{
  padding: 0 10px !important;
}
